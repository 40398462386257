.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: -15px;
}

.statistics {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
  .statistics {
    grid-template-columns: 3fr 2fr;
  }
}

@media (min-width: 1440px) {
  .statistics {
    grid-template-columns: 2fr 1fr;
  }
}
