.container {
  overflow: hidden;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.table thead tr th {
  background-color: rgba(238, 240, 242, 0.8);
  border-bottom-width: 2px;
}

.table tbody {
  background-color: rgba(250, 250, 250, 0.8);
}

.delete {
  width: 280px;
}

.action {
  width: 200px;
}
