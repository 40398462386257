.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  justify-content: center;
}

@media (min-width: 426px) {
  .container {
    grid-template-columns: repeat(auto-fit, 350px);
  }
}

@media (min-width: 1400px) {
  .container {
    grid-template-columns: 400px 400px;
  }
}

@media (min-width: 1800px) {
  .container {
    grid-template-columns: repeat(4, 350px);
  }
}
