.container {
  padding: 4px 12px 6px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #d0bfff;
}

@media (max-width: 319px) {
  .group {
    gap: 0;
  }
}
