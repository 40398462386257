.container {
  height: 100%;
  background-color: #e6eeff;
  background: linear-gradient(45deg, #cfddfc 0%, #ededed 100%);
}

@media (min-width: 426px) {
  .container {
    display: grid;
    place-content: center;
  }
}

@media (min-width: 426px) and (max-height: 820px) {
  .container {
    min-height: 100%;
    height: auto;
    padding: 30px;
  }
}
