.container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  font-size: 1.3rem;
  font-weight: 500;
}

.actions {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.action-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
