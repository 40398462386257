.button {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #42526e;
  font-size: 0.95rem;
  font-weight: 600;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  border-radius: 4px;
}

.button:hover {
  background-color: #ced4da;
}
