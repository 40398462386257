.delete {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.action-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
