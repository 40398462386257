.modal {
  width: 400px;
}

.box {
  min-height: 811px;
}

@media (min-width: 768px) {
  .modal {
    width: 800px;
  }

  .box {
    min-height: 384px;
  }
}
