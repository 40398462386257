.container {
  height: 100%;
  background-color: #e6eeff;
  background: linear-gradient(45deg, #cfddfc 0%, #ededed 100%);
}

.card {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  font-weight: bold;
  font-size: 1.7rem;
  color: var(--violet);
}

.title {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}

@media (min-width: 426px) {
  .container {
    display: grid;
    place-content: center;
  }

  .card {
    width: auto;
    min-width: 400px;
    min-height: auto;
  }
}

@media (min-width: 426px) and (max-height: 850px) {
  .container {
    min-height: 100%;
    height: auto;
    padding: 30px;
  }
}
